import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-admin-hash-dialog',
    standalone: true,
    imports: [MatDialogModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, MatInputModule],
    templateUrl: './admin-hash-dialog.component.html',
    styleUrl: './admin-hash-dialog.component.scss',
})
export class AdminHashDialogComponent {
    constructor(private readonly dialogRef: MatDialogRef<AdminHashDialogComponent>) {}
    hash = new FormControl('', { validators: [Validators.required] });

    closeOnEnter(): void {
        if (this.hash.invalid) {
            return;
        }
        this.dialogRef.close(this.hash.value);
    }
}
