<h1 mat-dialog-title>Extra security check</h1>
<mat-dialog-content>
    <div>
        <mat-form-field appearance="fill">
            <input [formControl]="hash" matInput type="password" (keyup.enter)="closeOnEnter()" />
            <mat-label>Fill in extra security check</mat-label>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button color="primary" mat-button [disabled]="hash.invalid" [mat-dialog-close]="hash.value">Confirm</button>
</mat-dialog-actions>
